/*@import url('https://fonts.googleapis.com/css?family=Oswald');*/
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,300,400,600,700');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400');
/*@import url('https://fonts.googleapis.com/css?family=Open+Sans');*/
/*@import url('https://fonts.googleapis.com/css?family=Fjalla+One');*/
/*@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500&display=swap');*/

body {
    font-family: 'Montserrat', sans-serif;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    position: relative;
    overflow-x: hidden;
}

.vh-100 {
    height: 100vh !important;
}

.h-100 {
    height: 100% !important;
}

html {
    font-size: 100%;
    scroll-behavior: smooth;
}

h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    font-weight: 600;
    /*text-transform: uppercase;*/
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 20px;
}

h2 {
    font-family: 'Montserrat', sans-serif;
    color: #1a1a1a;
}

b {
    font-weight: 600;
}

h3 {
    font-size: 1.688rem;
    margin-bottom: 10px;
    /*color: #e35205;*/
    font-family: 'Montserrat', sans-serif;

}

h4 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;

}
strong{
    font-weight: bold;
}

.fnc h4 {
    color: #fc5a13;
    font-weight: bold;
    font-size: 3rem;
}

li {
    list-style-type: none;
}

/*ul li {*/
/*    !*font-family: 'Lato', sans-serif;*!*/
/*    font-weight: lighter;*/
/*}*/


.alert-danger ul {
    margin-bottom: 0;
}

.register-container .alert-danger {
    margin-top: 1rem;
}

.block-content li {
    font-family: 'Lato', sans-serif;
    font-size: 1.125rem;
    font-weight: 300;
    margin-bottom: 20px;
    color: #7e8c99;

}

.subhead {
    font-family: 'Lato', sans-serif !important;
    color: #7e8c99 !important;
    font-size: 1rem;
    font-weight: lighter;
    margin: 0 auto;
}

.bold {
    font-weight: bolder;
}

h5, h6 {
    margin: 0px 0px 5px;
    margin-top: 0px;
    font-family: "Montserrat", sans-serif;
    color: #435061;
    position: relative;
}

h6 {
    font-family: 'Montserrat', sans-serif;
}

hr {
    border-top: 1px solid #dae2e9;
}

.search-filter-form hr {
    margin: 25px 5px 40px 5px;

}

a {
    color: inherit;
    text-decoration: none !important;
}

p {
    color: #7e8c99;
    /*font-family: 'Lato', sans-serif;*/
    font-size: 1.063rem;
    font-weight: 300;
    /*margin-bottom: 5px;*/
}

/*ol, ol li, ul {
    color: #7e8c99;
    font-family: 'Montserrat', sans-serif;
}*/

/*Tools*/

.fw-lighter {
    font-weight: 400;
}

.surtitle {
    margin: 50px 0 0 7px;
    font-size: 0.875rem;
    font-weight: 700;
    color: #ababab;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #fc5a13;
}

/*Topbar*/
.top-bar {
    position: relative;
    padding: 0px !important;
    min-height: 0px;
    justify-content: flex-end;
    display: flex;
    background: #2b2b2b;

}

.top-bar a {
    padding: 9px 15px !important;
}

.top-bar li {
    padding: 0px !important;
    font-size: 0.75rem !important;
}

/*Bootsrap customize*/

.navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
}

#navbar .navbar-nav > .active > a {
    color: #fc5a13;
}

#navbar .navbar-nav > li > a {
    color: #333;
    background-color: transparent;
}

#navbar {
    justify-content: flex-end;
}

.row.d-flex.align-items-center {
    position: relative;
}

.input-group-text {
    background-color: #31a2e1 !important;
    color: white;
}

#search-block {
    margin: 20px 0 30px;
}

#overlay-green {

    width: 100%;
    height: 100%;
    background-color: #f3f6f8;
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.22);
}

#quick-search-bar {
    margin-bottom: 50px;
    position: relative;
}

#quick-search-bar input {
    font-size: 0.9rem;
}

.input-home {
    width: 90% !important
}

.search-home {
    height: 34px;
    width: 34px;
}

.main-navbar {
    min-height: 100px;
}

#containerNavbarCenter {
    background: #2b2b2b;
}

#containerNavbarCenter ul li a {
    color: white;
}

.navbar-nav li {
    font-family: montserrat;
    font-size: 1.2rem;
    letter-spacing: 2px;
    font-weight: 300;
    line-height: 1.02857143;
    position: relative;
}

.form-control {
    font-size: 0.9rem;
}

.form-control + .input-group-addon:not(:first-child) {
    width: 10%;
    background: #31a2e1;
    color: white;
    border: none;
}

/*.dropdown-menu {*/
/*    position: absolute;*/
/*    top: 100%;*/
/*    left: 0;*/
/*    z-index: 1000;*/
/*    display: none;*/
/*    float: left;*/

/*    min-width: 160px;*/
/*    padding: 5px;*/
/*    margin: 2px 0 0 -120px;*/
/*    margin-top: 2px;*/

/*    text-align: left;*/
/*    list-style: none;*/
/*    background-color: #fff;*/
/*    -webkit-background-clip: padding-box;*/
/*    background-clip: padding-box;*/
/*    border: 1px solid #ccc;*/

/*    border: 1px solid rgba(0, 0, 0, .15);*/
/*    border-top-color: rgba(0, 0, 0, 0.15);*/
/*    border-right-color: rgba(0, 0, 0, 0.15);*/
/*    border-bottom-color: rgba(0, 0, 0, 0.15);*/
/*    border-left-color: rgba(0, 0, 0, 0.15);*/
/*    border-radius: 4px;*/
/*    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);*/
/*    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);*/
/*}*/

.dropdown-menu:target {
    display: block;
}

.dropdown-menu a {
    color: #333;
}

.dropdown-toggle::after {
    display: none;

}

.user-information {
    display: none;
}

/*Logo*/
.logo {
    height: 90%;
    margin-right: 20px;
}

img#logo {
    height: 56px;
}

/*Owl-carousel*/

.owl-carousel {
    width: 100%;
}

.owl-item {
    float: left;
}


.more-informations a {
    padding: 10px 0;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    text-align: center;
    line-height: 2.5;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9375rem;
}

.more-informations {
    height: 40px;
    text-align: center;
    display: block;
    margin: 0 25px;

}

.orange {
    background: #ed6b3e !important;
}


.orange:hover {
    background: #fb833c !important;
}

.blue:hover {
    background: #44bae1;
}

.blue {
    background: #31a2e1;
}

.green {
    background: #3bb672 !important;
}

.green:hover {
    background: #7eb667 !important;
}

.green-color {
    color: #3bb672 !important;
}

.reinit {
    border-radius: 4px !important;
    line-height: 2;
}

.home-lst-srv h2 {
    margin-top: 18px;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    font-weight: bolder;
}

.home-lst-srv p, .home-lst-srv .icons {
    -webkit-transition: all 400ms linear;
    -moz-transition: all 400ms linear;
    -o-transition: all 400ms linear;
    -ms-transition: all 400ms linear;
    transition: all 400ms linear;
}

.home-lst-srv .item {

    -webkit-transition: all 400ms linear;
    -moz-transition: all 400ms linear;
    -o-transition: all 400ms linear;
    -ms-transition: all 400ms linear;
    transition: all 400ms linear;
    color: white;
    opacity: 0;
    transition: all 1s ease-out;
    transform: translateY(50px);
}

.home-lst-srv .item.show {
    transform: translateY(0);
    opacity: 1;
}

.home-lst-srv p:first-child {
    color: #7e8c99;
    height: 100px;
}

.home-lst-srv .item:hover {
    background: #fc5a13;
}

.home-lst-srv .item:hover g, .home-lst-srv .item:hover p, .home-lst-srv .item:hover h2 {

    color: white;
    fill: white !important;
    transition: all 200ms;
}

.home-lst-srv .item g {
    fill: #fc5a13;
}

.page-promoteur .home-lst-srv .row {
    margin-bottom: 30px;
}

.srv-description {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    min-height: 80px;
}

/*Liste des lots*/
.lot {
    margin: 20px 0 30px !important;

}

.lots-lst h2 {
    margin-top: 25px;
    font-size: 1.063rem;
    margin-bottom: 0px;
    color: #435061;
    font-weight: lighter;
}

.lots-lst .px {
    margin-top: 25px;
    font-size: 1.125rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: #31a2e1;

}

.lots-lst .cp {
    font-size: 0.875rem;
    font-family: 'Lato', sans-serif;
    color: #7e8c99;
}

.image_description_property {
    padding: 0px 15px;
    border: 1px solid #E2E2E2;

}

.property-features li {
    display: inline-block;
}

/*.lot-libelle {*/
/*width: 50%;*/
/*}*/

.property-features li + li {
    padding-left: 10px;
    border-left: 1px solid #ececec;

}

.image_description_property .property-features li {
    padding-right: 10px;
    line-height: 1.5;
    color: #7e8c99;
}

.image_description_property .property-features {
    padding: 0;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #ececec;
}

.image_description_property ul.property-features img {
    width: 21px;
    height: auto;
    margin-right: 5px;
}

.featured-property-icon {
    font-size: 1rem;
    background-color: #ffffff;
    color: #31a2e1;
    padding: 5px;
    position: relative;
    border: 1px solid gainsboro;
    outline: none !important;
    line-height: 1 !important;
    margin-left: 7px;
}

/*Search bar*/

/*Pagination*/

.pagination .active {
    background: #31a2e1;
    color: white;
}

.page-item.active .page-link {
    background-color: #337ab7;
    border-color: #337ab7;
}

.page-link {
    color: #337ab7;
}

.pagination li {
    font-size: 0.875rem;
}

.navbar .navbar-collapse {
    text-align: center;
}

.navbar {
    margin-bottom: 0px;
    box-shadow: 0 0 25px 1px rgb(0 0 0 / 15%);
}

.top-bar .active .nav-link {
    color: red !important;
    background-color: #e7e7e7;
}

.inner-page-header-area {

    min-height: 130px;

}

.text-orange {
    color: #fc5a13;
}

.text-black {
    color: black;
}

.bg-orange {
    /*background: linear-gradient(160deg, #fc5a13 0%, #FE506A  100%);*/
    /*background: linear-gradient(160deg, #48c981 0%, #28a745  100%);*/
    background: linear-gradient(160deg, #fc5a13 0%, #FE506A 100%);
}

.bg-green {
    /*background: linear-gradient(159deg, #2bb78a,#3CAEFE  100%);*/
    background: linear-gradient(326deg, #2bb78a, #3CAEFE 100%);
}


.bg-blue {
    background: linear-gradient(160deg, #3CAEFE, #7A62FE 100%);
}


.bg-dark-green {
    /*background: linear-gradient(160deg, #3CAEFE, #7A62FE 100%);*/
    background: linear-gradient(56deg, #3a5f63 0%, #1096a1 100%);
}

.bg-grey {
    background: #f8f8f8;
}

.bg-dark-blue {
    background: linear-gradient(170deg, #44809f, #1a252c 100%);
}


.btn-black {
    color: #fff;
    background-color: #333333;
    border-color: #333333;
}

.btn-blue-opal {
    color: #fff;
    background: #193c6c;
}

.btn-blue-opal:hover {
    color: #fff;
    background: black;
}


.btn-outline-black {
    color: #333333;
    border-color: #333333;
}

.btn-outline-white {
    color: #fff;
    border-color: white;
}

.btn-outline-white:hover, .btn-outline-white:hover a {
    color: #fff !important;
    background-color: #3c3939;
    border: none;

}

.btn-primary:hover, .btn-primary:hover a {
    color: #fff !important;
    background-color: #2828bdb8;
    border: none;

}


#header-content {
    padding-top: 100px;
    padding-bottom: 150px;
}


.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
    transition: all 2s ease-in-out 0s;
}

.dropdown-menu {
    border: none;
}

.icon-tabler-circle-1, .icon-tabler-circle-2 {
    margin-right: 5px;
}

/*#page-features .icon-tabler{*/
/*    padding:0px 20px 5px 0px;*/
/*}*/

/*.icon-with-text-shortcode {*/
/*    position: relative;*/
/*    flex-flow: column wrap;*/
/*    align-items: center;*/
/*    display: grid;*/
/*    grid-gap: 0;*/
/*    grid-template-rows: auto;*/
/*}*/

/*Filter search*/
#datetimepicker1 {
    font-size: 0.625rem !important;
    width: 100%;
    margin-top: 30px;
}

.navbar-default {
    border-color: transparent;
}

.navbar-default .navbar-collapse {
    border-color: transparent;
}

/*Slider range*/
#minSurface, #maxSurface {
    border: 1px solid #ececec;
    height: 43px;
    margin-bottom: 10px;
    padding-left: 10px;
    max-width: 133px;

}


.search-filter-form-sb .filter-number-range {
    width: 100%;
}

/*Search filter form*/
.search-filter-form {
    background: #f6f6f6;
    box-shadow: 0px 1px 1px 0px #dddddd;
    clear: both;
    padding: 15px 0px;
    width: 100%;
}

.search-filter-form .fa-search {

    font-size: 20px;
}

.search-filter-form input.checkbox-inline {
    height: 45px;
}

.search-filter-form input {
    min-height: 32px;
    font-size: 0.9rem !important;
}

.search-filter-form label {
    height: 45px;
}

.search-filter-form label {
    line-height: 43px;
}

.search-filter-form .filter-footer {
    border-top: 1px solid #ececec;
    margin-bottom: 20px;
    margin-top: 10px;
}

.filter-header {
    float: left;
    width: 100%;
    min-height: 60px;
    padding: 23px 30px;
    background-color: grey;
    position: relative;
}

.filter-header h4 {
    color: #4b4848;
    margin-bottom: 0px;
}

.filter-header ::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-width: 0 19px 19px 0;
    border-style: solid;
    border-color: #c4c6c9 white;
}

.search-filter-form .submit-filter, .promoteur_unit .submit-filter {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    font-weight: normal;
    background-color: #3bb672;
    border: medium none;
    color: #ffffff;
    height: 45px;
    text-transform: uppercase;
    border-radius: 4px !important;
    width: 100%;
}

.search-filter-form .submit-filter:hover {
    background-color: #42cb7f;

}


.search-filter-form .form-control, .promoteur_unit .form-control {
    border: 1px solid #ececec;
    height: 43px;
}

#prospect-prsc-form .submit-filter {
    width: 100% !important;
}

.promoteur_unit .fa-check {
    color: #3bb672 !important;
}

input::placeholder {
    color: #7e8c99 !important;
    opacity: 1;
}

.right-align {
    float: right;
}

input#amount, input#sfc {
    background: transparent;
}

/*.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {*/
/*background: #31a2e1;*/
/*}*/

.ui-corner-all {
    border-bottom-right-radius: 8px;
}

.ui-corner-all {
    border-bottom-left-radius: 8px;
}

.ui-corner-all {
    border-top-right-radius: 8px;
}

.ui-corner-all {
    border-top-left-radius: 8px;
}


.fs-title {
    font-size: 25px;
    color: #2C3E50;
    /*margin-bottom: 30px;*/
    font-weight: bold;
    text-align: left
}

#modal-reservation .fs-title, #modal-prospect .fs-title {
    font-size: 20px;
    margin-top: 5px;
}

#front-slider-level {
    border: 1px solid #E2E2E2;
}

.nav-tabs > li > a:hover {
    border-color: transparent;
}

.entityNav .nav-item {

    background: white !important;
    color: #31a2e1 !important;
    border: 1px solid #E2E2E2;
    box-shadow: 1px 1px 3px 1px #dddddd;
}

.entityNav .active a {
    background: #31a2e1;
    color: white;

}

.entityNav .active a:hover {
    background: #2f96d2;
    color: white;

}

.entityNav .active {

    border: 1px solid #31a2e1 !important;
    box-shadow: 1px 1px 2px 1px #dddddd;
}

.entityNav li a {

    color: #5f6265;
}

.entityNav .first {
    padding: .5rem 1rem;
}

/*Lot détails*/

.property-detail-info-title {
    width: auto;
    float: left;
    margin: 5px 20px 10px 0 !important;
    font-size: 2.188rem;

}

.information-label {
    font-weight: bold;
}

.information-label, .information-value {
    font-family: "lato", sans-serif;
    font-size: 1rem;
    line-height: 1.9;
    color: #7e8c99;
}

.property-detail-info {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.property-detail-info h1 {
    color: #ffffff;
    float: left;
}

.property-detail-info .property-detail-facility {
    margin: 20px 0;

}

.property-detail-info .property-detail-facility div {
    padding: 0;
}

h2.widgettitle {
    margin: 0px;
    color: #ffffff;
    font-size: 1.125rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

.filter-widget-body, .agent-unit-wrapper {
    float: left;
    width: 100%;
    padding: 30px;
    border: 1px solid #dae2e9;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: rgb(218, 226, 233);
    border-top: none;
}

.search-filter-form-sb select {
    border: 1px solid #ececec;
    color: #435061 !important;
    font-size: 0.875rem;
    font-weight: normal !important;
    height: 45px;
    margin-bottom: 15px;
    padding: 13px;
    text-align: center;
    width: 100%;
}

.criterion {
    margin-bottom: 15px;
}

.search-filter-form-sb label {
    color: #7e8c99;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: lighter;
}

.search-filter-form-sb .submit-filter, .promoteur_unit .submit-filter {
    margin-top: 15px;
    #background-color: #3bb672;;
    border: medium none;
    color: #ffffff;
    font-weight: bold;
    height: 45px;
    text-transform: uppercase;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.875rem;
    font-weight: normal
}


.sld-txt {
    font-size: 2rem;
    color: rgb(74, 76, 76);

}

.sld-title {
    line-height: 55px;
    margin-bottom: 10px;
    color: #fc5a13;
    font-style: italic;
}

.sld-txt-content {
    font-size: 2.975rem;
    font-weight: 700;
}


.slider-sm .sld-txt-content {
    font-size: 3.975rem;

}

p.sub-heading {
    font-size: 1.5rem;
}



.container-wrap {
    /*justify-content: center;*/
    /*text-align: center;*/
    padding-top: 8%;
    padding-right: 12%;
    padding-left: 12%;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 1;
    width: 100%;
    height: 100%;
}


#header-content p {
    color: white;
}

#lot-ttl .fa-star {
    line-height: 1.2;
}

.image-with-label {
    overflow: hidden;
}

.image-with-label .fa-star, .featured-property-icon .fa-star {
    color: #31a2e1;
}

.image-with-label img, .request-option {
    transition: all 0.3s ease-in-out 0s;
}

.image-with-label:hover img {
    transform: scale(1.1);
}

.request-option:hover {
    background: #3bb672;
}

.fa-envelope {
    margin-right: 5px;
}

.agent-unit-wrapper .fa-user {
    font-size: 100px;
}

.descriptif {
    font-size: 0.875rem;
    line-height: 1.8;
}

.promoteur_unit {
    background-color: #fdfdfd;
}

.agent_detail i {
    margin-right: 10px;
    min-width: 14px;
    line-height: 1.9;
}

.content_agent h4 {
    border-bottom: none !important;
    margin-bottom: 10px;
    color: #333;
}

.content_agent {
    text-align: center;
}

.agent-unit-img-wrapper {
    padding: 0 30px 10px;

}

.agent-unit-img-wrapper img {
    display: block;


}

.fa-user-wrapper {
    text-align: center;

}

.agent-unit-wrapper {

    padding-bottom: 30px;
}

.cnt-contact {
    margin-bottom: 30px;
}

.search-filter-form-sb {
    margin-bottom: 30px;
}

.bkg-blue {
    background-image: linear-gradient(to bottom, #e0e1e4 0%, #e6e9ee 100%);
}

.orange-bkg {
    background-color: #ff551a;
}

/*Google Map*/

#map {
    height: 350px;
}

#search-map {
    height: 600px;
}

/*  Pop-up*/

.button {
    border: 1px solid #333;
    background: #6479fd;
}


/*start*/


.modal-dialog .close {
    height: 30px;
    margin-bottom: 5px;
    margin-top: 0;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 30px;
    font-size: 1.5rem;

}

.body-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    overflow: hidden;
    z-index: 4; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    /* Enable scroll if needed */
}

#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey
}

#progressbar .active {
    color: #000000
}

#progressbar li {
    list-style-type: none;
    font-size: 12px;
    width: 33.33%;
    float: left;
    position: relative
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: -1
}

#progressbar li.active i,
#progressbar li.active:after {
    background: skyblue
}


#progressbar i {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 18px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px
}

#progressbar svg {
    width: 24px;
    height: 24px;
    fill: white;

}

#msform {
    text-align: center;
    position: relative;
    margin-top: 20px
}

#msform fieldset .form-card {
    background: white;
    border: 0 none;
    border-radius: 0px;
    padding: 20px 40px 30px 40px;
    box-sizing: border-box;
    width: 94%;
    margin: 0 3% 20px 3%;
    position: relative
}

#msform fieldset {
    background: white;
    border: 0 none;
    border-radius: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding-bottom: 20px;
    position: relative
}

#msform fieldset:not(:first-of-type) {
    display: none
}

#msform fieldset .form-card {
    text-align: left;
    color: #9E9E9E
}

#msform input, #msform textarea {
    padding: 0px 8px 4px 8px;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    font-family: montserrat;
    color: #2C3E50;
    font-size: 16px;
    letter-spacing: 1px
}

#msform input:focus,
#msform textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: none;
    font-weight: bold;
    border-bottom: 2px solid skyblue;
    outline-width: 0
}

#msform .action-button {
    width: 20%;
    background: skyblue;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 0px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px
}

#msform .action-button:hover,
#msform .action-button:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px skyblue
}

#msform .action-button-previous {
    width: 20%;
    background: #616161;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 0px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px #616161
}

.card {
    z-index: 0;
    border: none;
    border-radius: 0.5rem !important;
    position: relative
}

.modal-input {
    background: #F4F5F7;
    padding: 10px 15px;
    margin-bottom: 10px;

    border: 1px solid #dadbdd !important;
}

.modal-dialog {

    pointer-events: auto;
}

.dialog, .dialog-prospect, #dialog-criterion {
    padding: 10px;
    position: absolute;
    display: none;
    position: fixed;
    top: 1%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}

.information-message, .information-fv-message {
    padding: 10px;
    position: absolute;
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}

/*stop*/

#dialog-criterion {
    display: none;
}

input[type=text] {
    display: inline-block;
    /*  margin:5px;*/
}

/*.fos_user_registration_register input[type=text] {*/
/*    border: 1px solid #ccc;*/
/*    display: inline-block;*/
/*    !*  margin:5px;*!*/
/*}*/

/*.fos_user_registration_register label{*/
/*    font-size: 0.9rem;*/
/*}*/

.content-wrapper {
    margin-top: 50px;
    min-height: 150px;
}

.content-wrapper label {
    font-family: "Montserrat";
    font-weight: lighter;
}

#forget-password {
    margin-top: 10px;
    clear: both;
}

#modal-prospect input#price-option::placeholder {
    color: #777;
}


/*.option-form-window, .option-form-window-prospect, .option-form-window-criterion {*/
/*float: right;*/
/*height: auto;*/
/*padding: 40px;*/
/*background-color: white;*/
/*width: 100%;*/
/*color: #777;*/
/*border-radius: 10px;*/
/*}*/

textarea#note-option {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #dadbdd;
}


/*.option-form-window #sendOption, .option-form-window-prospect #sendOption, #search-send, .option-form-window #sendOptionByPres {*/
/*height: 40px;*/
/*margin-top: 5px;*/
/*width: 50%;*/
/*background-color: #0e90d9;*/
/*border-radius: 10px;*/
/*color: white;*/
/*font-family: "Montserrat";*/
/*font-size: 0.875rem;*/
/*text-transform: uppercase;*/
/*transition: all 0.3s;*/
/*-webkit-transition: all 0.3s;*/
/*-moz-transition: all 0.3s;*/
/*-o-transition: all 0.3s;*/
/*opacity: 1;*/
/*margin-top: 15px;*/
/*border: none;*/
/*}*/

/*.option-form-window .form-title, .option-form-window-prospect .form-title {*/
/*font-family: "Montserrat";*/
/*font-weight: bold;*/
/*font-size: 1.375rem;*/
/*color: #777;*/
/*margin: 0px 5px 20px;*/

/*}*/

#note-option {
    width: 100%;
}


.personal-informations {
    display: none;
}


#message, #fav-message {
    text-align: center;
    color: #777;
    font-size: 1.125rem;
}

.message-form-window {
    border-radius: 10px;
    background-color: white;
    height: 180px;

}

.message-form-window .form-title {
    padding: 10px;
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 1.5rem;
    color: #777;
    text-align: center;

}

.message-form-window p {
    color: #31a2e1;
    padding: 10px;

}


/*.option-form-window input, . input#criterion-save {*/
/*width: 100%;*/
/*height: 40px;*/
/*margin-bottom: 10px;*/
/*background-color: #0c84c7;*/
/*border: none;*/
/*font-size: 1.063rem;*/
/*font-family: "Lato";*/
/*color: #b1e2ff;*/
/*padding: 10px 15px;*/
/*}*/

/*.option-form-window-prospect label {*/
/*color: white;*/
/*margin-right: 5px;*/
/*font-weight: lighter;*/
/*}*/

/*.option-form-window input::placeholder, .option-form-window-prospect input::placeholder {*/
/*color: #444;*/

/*}*/

input#price-option::placeholder {
    color: #b1e2ff;

}

/*.option-form-window-prospect input#criterion-save::placeholder {*/
/*color: #b1e2ff;*/
/*font-weight: lighter;*/
/*font-family: "Montserrat";*/

/*}*/

#sendOption:hover {
    background: #0c7cbb;
}

/*.option-form-window button, .option-form-window-prospect button {*/
/*background-color: #ffffff;*/
/*color: #0e90d9;*/
/*font-family: "Montserrat";*/
/*font-weight: bold;*/
/*border: none;*/
/*text-transform: uppercase;*/
/*opacity: 1;*/
/*}*/

.message-form-window button {
    background-color: #ffffff;
    color: #0e90d9;
    font-family: "Montserrat";
    font-weight: bold;
    border: none;
    text-transform: uppercase;
    opacity: 1;
}

.message-form-window .ok {

    background: #0e90d9;
    color: white;
    text-align: center;
    width: 150px;
    border-radius: 10px;
    height: 35px;
    margin: 20px auto 20px;
    line-height: 35px;
}

.message-form-window .close {
    height: 25px;
    margin-bottom: 5px;
    margin-top: 0;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 25px;
    font-size: 1.5rem;

}

.register-container label.bolder {
    font-weight: 600 !important;
    font-size: 1rem;

}

.flash-notice {
    margin: 20px 0;
}

.register-container .form-control {
    height: 40px;
}

/*.dropdown-toggle {*/
/*    float: right;*/
/*    font-size: 1.375rem;*/
/*}*/

.dropdown-item {
    line-height: 2.5 !important;
}

.dropdown-item:focus > a, .dropdown-item:hover > a {
    color: #fc5a13 !important;
    transition: all 0.4s ease;

}

.nav-item:focus > a, .nav-item:hover > a {
    color: #fc5a13 !important;
    transition: all 0.4s ease;

}

.navbar-nav .nav-item::before {
    content: "";
    position: absolute;
    background: transparent;
    width: 100px;
    height: 2px;
    left: -30px;
    top: -2px;
    z-index: 1;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.navbar-nav .nav-item::before:hover {
    background: black;
}


.dropdown-item:focus, .dropdown-item:hover {

    background: rgba(233, 234, 234, 0.38);
}

.dropdown-item.active, .dropdown-item:active {
    color: #fc5a13;
    background: rgba(233, 234, 234, 0.38);
}

.search-bar, .search-bar h1 {
    text-align: center;
}

.center {
    text-align: center;
}

h1.with_lines {
    color: #333;
    position: relative;
}

.wrapper {
    position: relative;
}

.with_lines::before {
    right: 5%;
}

.with_lines::after {
    left: 5%;
}

.search-home-bar {
    margin: 0 auto;
    width: 40%;
}

.main_label {
    text-align: center;
    margin-bottom: 7px;
    font-size: 1.063rem;
    font-family: 'Oswald', sans-serif;
}

ul {
    padding-left: 0px;
}

ol {
    padding-left: 0px;
}

.breadcrumb > li + li::before {

    content: none !important;
}

.separator {
    margin: 0 5px;
}

.fil-ariane, .breadcrumb {
    background: #E6E9EE;
}

.sld-label {
    height: 35px !important;
    line-height: 35px !important;
}

.favourite-access {
    text-align: right;
    line-height: 2.5;
}

.img-wrapper {
    position: relative;
}

.image-overlay-content {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;

}

.image-overlay-content h4 {
    color: white;
    font-size: 20px;
    padding-top: 7px;
    margin-top: 12%;
    text-align: center;

}

.fa-file-pdf {
    color: #F15642;
}

.fa-facebook-square, .fa-twitter, .fa-print {
    font-size: 1.563rem;
}

.fa-print {
    color: #35455a;
}

.btn-lg {
    font-size: 0.875rem;
}

.btn-lg {
    border-radius: 5px;
}

.btn-demo {
    background: #3bb672 !important;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 25px 0 0 10px !important;
    float: left;
}

.btn-demo a {

    color: #FFFFFF;
}

.favorite-cnt a {
    color: #31a2e1;
}

.wrapper-login {
    padding: 50px;
    background: white;
    box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
}

/*.wrapper-login .btn {*/
/*    height: 50px;*/
/*    font-weight: 700;*/
/*    padding: 0.6rem 0.9rem;*/
/*    border-radius: 0.35rem;*/
/*}*/

.auth-form h4, #forgot-pw-form h4, #reset-pw-form h4, #create-pw-form h4 {
    font-size: 2.425rem;
    text-align: center;

}

.auth-form input[type="text"], .auth-form input[type="password"], .registration-form input[type="email"], .registration-form input[type="password"], .registration-form select, .registration-form input[type="text"] {
    border: 0.0625rem solid #EEEEEE;
}

.wrapper-login .form-control {
    height: 45px;
}

.wrapper-login h1 {
    text-align: center;
    margin-bottom: 40px;
}

.wrapper-login button {
    margin-bottom: 10px;
}


#checkbox-wrapper {
    font-size: 1.063rem;
}

.footer-nav a {
    color: white;
}

.footer-nav li {
    font-size: 0.75rem;
    display: inline-block;
    width: 50%;

}

.footer-nav li a {
    padding-top: 0px;
    padding-bottom: 20px;
}

footer h4 {
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 0.6875rem;
}

.footer-description, #footer-container .navbar-menu {
    margin-top: 30px;
    line-height: 25px;
}

#footer-container {
    z-index: 1;
    background: #262f35;
    color: #cbcfd4;
}

.footer-bs {

    padding: 60px 40px;
    color: rgba(255, 255, 255, 1.00);
    border-bottom-right-radius: 6px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 6px;
}

/*.footer-bs .footer-nav, .footer-bs .footer-social, .footer-bs .footer-ns {*/
/*padding: 10px 0px;*/
/*}*/

.footer-bs .footer-nav, .footer-bs .footer-social, .footer-bs .footer-ns {
    border-color: transparent;
}

.footer-bs .footer-brand h2 {
    margin: 0px 0px 10px;
}

.footer-bs .footer-brand p {
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.70);
}

.footer-bs .footer-nav ul.pages {
    list-style: none;
    padding: 0px;
}

.footer-bs .footer-nav ul.pages li {
    padding: 5px 0px;
}

.footer-bs .footer-nav ul.pages a {
    color: rgba(255, 255, 255, 1.00);
    font-weight: bold;
    text-transform: uppercase;
}

.footer-bs .footer-nav ul.pages a:hover {
    color: rgba(255, 255, 255, 0.80);
    text-decoration: none;
}

#partner a {
    color: #337ab7;
    font-size: 0.875rem;
}

.nav > li > a:hover {

    color: #3bb672;
    background: none;
}

.footer-bs .footer-nav h4 {
    font-size: 0.6875rem;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 10px;
}

.footer-bs .footer-nav ul.list {
    list-style: none;
    padding: 0px;
}

.footer-bs .footer-nav ul.list li {
    padding: 5px 0px;
}

.footer-bs .footer-nav ul.list a {
    color: rgba(255, 255, 255, 0.80);
}

.footer-bs .footer-nav ul.list a:hover {
    color: rgba(255, 255, 255, 0.60);
    text-decoration: none;
}

.footer-bs .footer-social ul {
    list-style: none;
    padding: 0px;
}

.footer-bs .footer-social h4 {
    font-size: 0.6875rem;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.footer-bs .footer-social li {
    padding: 0px 4px 10px;
}

.footer-bs .footer-social a {
    color: rgba(255, 255, 255, 1.00);
}

.footer-bs .footer-social a:hover {
    color: rgba(255, 255, 255, 0.80);
    text-decoration: none;
}

.footer-bs .footer-ns h4 {
    font-size: 0.6875rem;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 10px;
}

.footer-bs .footer-ns p {
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.70);
}

.footer-ns a {
    color: #337ab7;
    font-size: 0.875rem;
}

#legal-notices li {
    margin-bottom: 10px;
}

.subtitle {
    font-weight: 500;
    font-size: 1.25rem;
}

#footer-container .nav > li > a {
    padding-left: 0px;
    line-height: 1.8;
}

/*div[class^='col-md'] {*/
/*padding-left: 10px;*/
/*}*/

.sf-checkbox input[type="checkbox"] {
    margin-top: 5px;
}

.block-content {
    margin: 0 0 70px;
}

.block-content h2 {
    text-transform: uppercase;
    line-height: 1.5;
    margin: 0 auto 20px;
}

.block-content .fa-check {
    color: #3bb672;
    margin: 3px 7px 0 0;
    float: left;
}

.ptext {
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.6;
}

.btn-registration {
    background: #3bb672;
    font-size: 1.25rem;
    text-align: center;
    width: 200px;
    padding: 7px 0;
    margin-top: 30px;
}

.btn-registration a {

    color: white;

}

.img-screen {
    border: 1px solid lightgrey;
    padding: 20px;
    border-radius: 20px;
}

.feature-box-info {
    font-family: 'Lato', sans-serif;
    padding-left: 25px;
}

/*.padd-content {*/
/*padding: 0 15px;*/
/*}*/

.sidebar h2 {
    font-size: 1.25rem;
}

.image-with-label .featured-property-icon {
    font-size: 1rem;
    background-color: #ffffff;
    color: #31a2e1;
    padding: 5px;
    position: absolute;
    right: 25px;
    top: 20px;
    outline: none !important;
    line-height: 1 !important;
}

.fa-map-marker-alt {
    font-size: 1.25rem;
}


.map-list li {

    display: inline-block;
    width: calc(100% / 3.1);
    line-height: 30px;
}

.map-list .fa-map-marker-alt {
    color: #ff551a;
    font-size: 0.9375rem;
    margin-right: 5px;
}

.page {
    min-height: 300px;
}

#block-map-nav a {
    color: white;
}

#block-map-nav ul {
    display: block;
}

#block-map-nav ul li {
    float: left;
}


.green-background {
    background: #75c000;
}

.green-darker-background {
    background: #5ba600;
}

.blue-background {
    background: #31a2e1;

}

.block-map {

    text-align: center;
    font-size: 1.063rem;
    padding: 15px !important;
}

#block-map-nav {
    margin-bottom: 50px;
    display: block;
    min-height: 40px;
}

#block-map-nav .active a {
    color: #333 !important;
    background: white;

}

.fa-twitter {
    color: #1da1f2;
}

.fa-facebook-square {
    color: #3d5998;
    background: linear-gradient(white, white) 10px 84%/10px 9px no-repeat,
    radial-gradient(circle at center, white 63%, transparent 0);

}

#sync2 {
    margin-top: 15px;
}

#list-index {
    text-align: center;
    margin-bottom: 3%;
}

#list-index li {
    display: inline-block;

}

#list-index li a {

    font-size: 1.25rem;
    color: #31a2e1;

}

#more-criteria {
    text-align: center;
    color: white;
    background: #31a2e1;
    border-radius: 4px;
    height: 45px;
    font-size: 0.9375rem;
    padding: 10px;
    width: 100%;
}

#more-criteria:hover {

    background: #34acef;

}

#btn-search {
    float: left;
    border-radius: 4px;
    width: 100%;

}


#other-criteria {
    display: none;
    font-size: 0.875rem;
}

#other-criteria .checkbox-block .form-check-label::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 13px;
    width: 18px;
    height: 18px;
    border: 2px solid #e2e2e2;
    background: #fff;
    margin-right: 5px;
}

#other-criteria .form-check-inline {
    display: inline-flex !important;
}

.input-block {
    display: inline-block;
}

.checkbox-block {
    margin-bottom: 10px;
    display: inline-block;
}

.checkbox-block .fa-check {
    display: none;
    position: absolute;
    left: 3px;
    top: 14px;
    color: #3bb672;
}

.checkbox-block input {
    display: none;
}

.checkbox-block ul {
    margin-bottom: 0px;
}

/*.checkbox-block ul li {*/
/*display: inline-block;*/
/*line-height: 25px;*/
/*}*/

.checkbox-block label {
    padding: 0 25px;
    margin-bottom: 0px;
    position: relative;
}

.checkbox-block label:first-child {
    padding: 0 25px 0 0px;

}

.filter-number-range {
    display: block;
}

#front-slider-level {
    min-width: 200px;
}

.form-inline .form-group {

    margin-left: 5px;
}

#item_filter_programme_fiscalite_parent input {
    height: 34px;
}


#dateLivraison {
    height: 43px;
    border: 1px solid #E2E2E2;
}


.criteria-title {
    margin-bottom: 20px;
    color: #4d4d4d !important;
    font-weight: 600 !important;
    font-family: 'Montserrat', sans-serif !important;
}

.mrg-btm-30 {
    margin-bottom: 30px;
}

/**[id^=tabs-] {*/
/*min-height: 400px;*/
/*}*/

.legal-notices-page h2 {

    font-size: 1.438rem;
    margin-bottom: 20px;
}

.legal-notices-page h3 {
    color: #444343;
    margin: 20px 0;
    font-size: 1.063rem;
}

.legal-notices-page h4 {
    font-weight: lighter;
    font-style: italic;
    font-size: 1.063rem;
}

.legal-notices-page ul {
    margin-left: 20px;
}

.legal-notices-page ul li {
    line-height: 35px;
    font-size: 1.063rem;
    color: #7e8c99;
    font-family: 'Lato', sans-serif;
}

.legal-notices-page ul li span {
    margin-left: 10px;
    padding-left: 60px;

}

.legal-notices-page li .fa-check {
    margin-right: 10px;
    color: #3bb672;

}

.legal-notices-page li .feature-box-icon {

    display: inline-block !important;
    float: left !important;
}

.legal-notices-page li .feature-box-info {

    padding-left: 30px;
}

.legal-notices-page p {
    margin: 20px 0;
    line-height: 32px;
    font-family: "Open Sans", Arial, sans-serif;
}

.legal-notices-page hr {
    margin: 20px 0 10px;
}

.wrap-content {
    margin-bottom: 40px;
}

.error {
    /*margin-top: 10px;*/
    color: red;
    font-size: 0.8125rem;
    display: block;
}

.error-msg {
    color: red;
}


#regForm {
    background-color: #ffffff;
    margin: 0 auto;
    padding: 40px;
    width: 70%;
    min-width: 300px;
}


/* Mark input boxes that gets an error on validation: */
input.invalid {

    border: 1px solid red !important;
}

/* Hide all steps by default: */
.tab {
    display: none;
}


/*button:hover {*/
/*    opacity: 0.8;*/
/*}*/

/*.button:hover {*/
/*    background: #a4a9fd;*/
/*}*/

#prevBtn {
    background-color: #bbbbbb;
}

/* Make circles that indicate the steps of the form: */
.step {
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbbbbb;
    border: none;
    border-radius: 50%;
    display: inline-block;
    opacity: 0.5;
}

.step.active {
    opacity: 1;
}

/* Mark the steps that are finished and valid: */

#regForm, #regForm input {
    font-family: 'Montserrat', sans-serif;

}


.wrap-input {
    padding: 60px 20px;
    border: 1px solid #CCC;
    position: relative;
}

#btn-wrapper {

    margin-top: 20px;
}

#nextBtn {
    background-color: #4CAF50;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 17px;
}


#prevBtn {
    background-color: #bbbbbb;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 17px;
}


/*Simulateur formulaire*/

div#sliderContainer {
    overflow: hidden;
    border: 1px solid #CCC;
}

div#sliderContainer .form-simulateur .form-case {


    background: white !important;
    width: 20%;
    float: left;
    text-align: center;

}

div#sliderContainer .form-simulateur .form-case input {

    font-size: 1.125rem;
}

div#sliderContainer .form-simulateur {
    position: relative;
    width: 500%;
    margin: 0;
    transition: left 1s;
    -webkit-transition: left 1s;
    text-align: left;
    font-size: 0;
}

.simulateur-form .etape1 .form-simulateur {

    left: 0;
}

.simulateur-form .etape2 .form-simulateur {

    left: -100%;
}

.simulateur-form .etape3 .form-simulateur {

    left: -200%;
}

.simulateur-form .etape4 .form-simulateur {
    left: -300%;
}

.simulateur-form .etape5 .form-simulateur {

    left: -400%;
}

.simulateur-form .btn-success {

    line-height: 1.12857 !important
}

#sliderContainer {
    max-width: 100%;
}


#sliderContainer input {
    width: 280px;
    display: block;
    margin: 0 auto;
    padding: 5px;
    border: 1px solid #CCC;
    height: 50px;

}


.form-container {
    border: 1px solid #CCC;
    max-width: 800px;
    margin: 0 auto;
}


.form-element {
    display: inline-flex;
    height: 130px;
    align-items: center;
}


.form-label {
    font-size: 1rem;
    display: block;
    /*background: #43596b;*/
    color: white;
    /*padding: 15px 0;*/
}

.simulateur-form button, .simulateur-form .btn-success {
    margin-left: 10px;
    background: #4caf50;
    color: white;
    font-size: 1.125rem;
    padding: 15px 30px;
    line-height: 0.42857;
    height: 50px;
    border: 0;
}

.faps {
    display: block;
    position: relative;
    z-index: 2;
    top: 27px;
}

.faps li {
    display: inline-block;
    color: white;
    font-size: 18px;
    text-transform: uppercase;
    margin-left: calc(20px + 20px / 3);
    width: calc((100% / 3) - 22px);
    padding: 15px 20px;
    vertical-align: top;
    box-shadow: 5px 5px 5px #CCC;
}

.faps .gratis {
    background-color: #31a2e1;
    margin-left: 0;
}

.faps .quick {
    background-color: #4caf50;
}

.faps .nice {
    background-color: #fc5a13;
}

.acces-denied-page ul li {
    margin-bottom: 15px;
    color: #7e8c99;
}

.acces-denied-page a {
    color: #337ab7;
}

.acces-denied-page p {
    font-size: 1.3rem;
}

.acces-denied-page ul li {
    font-size: 1.2rem;
}


.divider.light:after {
    position: relative;
    float: right;
    background: #dae2e9;
    margin-top: 2px;
    width: 100%;
    height: .5px;
    content: "";
}


/* Presentation only */
.width80 {
    width: 30%;
    margin: 0px auto;
    padding-top: 20px;
}


/*Media Queries*/
@media (min-width: 1200px) {


    .container {
        max-width: 1320px;
    }
}


/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 576px) {

    .slider-xs {
        display: none !important;
    }

    h1 {
        font-size: 1.6rem;

    }

    h2 {
        font-size: 1.5rem;

    }

    .btn-registration {
        width: 100%;
    }

    html {
        font-size: 95%;
    }
}

/*Small devices (landscape phones, less than 768px)*/
@media (max-width: 768px) {

    .item-diaporama {
        margin-bottom: 20px;
    }

    .container {
        max-width: 100%;
    }

    .nopadding-xs {
        padding-left: 0 !important;
        padding-right: 0 !important;

    }

    .label-checkbox {
        display: block;
    }

    .right-align {
        float: none;
    }

    .right-align .checkbox-inline {
        display: block;
        margin-left: 0px;
    }

    .sf-checkbox .checkbox-inline {
        margin-left: 0px;
    }

    .property-detail-info-title {
        font-size: 1.563rem !important;
    }

    #footer-container .navbar-nav {
        margin-left: 0px;
    }

    .navbar-brand {

        min-height: 90px;
    }

    .property-features li {
        display: block;
        text-align: left;
        line-height: 2 !important;
        width: 100%;
        padding-left: 0px !important;
    }

    .filter-button div {
        margin-bottom: 10px;
    }

    .search-home-bar {
        width: 100%;
    }

    .favourite-access {
        text-align: left;
    }

    .with_lines::before, .with_lines::after {
        display: none;
    }
}


@media (min-width: 768px) {
    .footer-nav {
        padding-left: 20px !important;
    }

    .footer-bs .footer-nav, .footer-bs .footer-social, .footer-bs .footer-ns {
        border-left: solid 1px rgba(255, 255, 255, 0.10);
    }

}

@media (max-width: 1300px) {

    .sld-title {
        font-size: 1.375rem;
        line-height: 24px !important;
    }

    .sld-txt-content {
        font-size: 2.2rem !important;
    }

    .with_lines::before, .with_lines::after {
        width: 15% !important;
    }

}


@media (max-width: 1400px) {

    .sld-txt-content {
        font-size: 2.475rem;
    }

    p.sub-heading {
        font-size: 1.3rem;
    }

}

@media (min-width: 992px) and (max-width: 1200px) {

    /*#client-section img, #multi-section img, #site-section img{*/
    /*    width: 600px !important;*/
    /*}*/


}


/*Large devices (desktops, less than 1200px)*/
@media (max-width: 1200px) {
    .home-lst-srv .item {
        margin-bottom: 50px;
    }

    /*#client-section img, #multi-section img, #site-section img{*/
    /*    width: 750px */
    /*}*/


}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 700px;
        margin: 1.75rem auto;
    }
}

@media all and (min-width: 992px) {

    .navbar .nav-item .dropdown-menu {
        display: block;
        opacity: 0;
        visibility: hidden;
        transition: .3s;
        margin-top: 0;
    }

    .navbar .nav-item:hover .nav-link {
        color: #fff;
    }

    .navbar .dropdown-menu.fade-up {
        top: 180%;
    }

    .navbar .nav-item:hover .dropdown-menu {
        transition: .3s;
        opacity: 1;
        visibility: visible;
        top: 100%;
        transform: rotateX(0deg);
    }
}

/*Medium devices (tablets, less than 992px)*/
@media (max-width: 992px) {

    #client-section img, #multi-section img, #site-section img {
        width: 100%;
    }

    .mln-200 {
        margin-left: 0px !important;
    }

    .mrn-300 {

        margin-right: 0px !important;

    }

    .mln-200 {
        margin-left: 0px !important;
    }

    .img-margin-left {
        margin-left: 0px !important;
    }

    #slider .svg-effect {
        height: 70px !important;

    }

    .favourite-access {
        text-align: left;

    }

    .property-detail-info-title {
        font-size: 2.125rem;
    }

    .search-home-bar {
        width: 50%;
    }

    .with_lines::before {
        right: 0% !important;
    }

    .with_lines::after {
        left: 0% !important;
    }
}


.radio-group {
    position: relative;
    margin-bottom: 25px
}

.radio {
    display: inline-block;
    width: 204px;
    height: 104px;
    border-radius: 0;
    background: lightblue;
    box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    cursor: pointer;
    margin: 8px 2px
}

.radio:hover {
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3)
}

.radio.selected {
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1)
}

.fi-cwsuxl-check {
    display: none;
    font-size: 39px;
    color: #4CAF50;
}

#preloader {
    margin: 0 auto;
    display: none;
}

.my-check {
    display: none;
}

/* .navbar-nav {*/
/*    padding-left: 30px;*/
/*}*/
.navbar-nav .nav-item {
    position: relative;
    z-index: 1;
}

.navbar-nav .nav-item::before {
    content: "";
    position: absolute;
    background: transparent;
    width: 0px;
    height: 2px;
    left: -10px;
    top: -2px;
    z-index: 1;
}

.navbar-nav .nav-item:last-child .nav-link {
    margin-right: 0;
}

.main-navbar .navbar-nav .nav-item .nav-link {
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    display: inline-block;
    position: relative;
    margin: 0 70px 0 0 !important;
    padding: 19px 0 20px 0;
}

.navbar-nav .nav-item .nav-link::after {
    display: none;
}

.navbar-nav .nav-item .nav-link i {
    font-size: 10px;
}

.navbar-nav .nav-item:hover::before {
    width: calc(100% - 50px);
    background-image: -moz-linear-gradient(0deg, #086ad8 0%, #378cff 100%);
    background-image: -webkit-linear-gradient(0deg, #086ad8 0%, #378cff 100%);
    background-image: -ms-linear-gradient(0deg, #086ad8 0%, #378cff 100%);
}

.navbar .dropdown-menu {
    border: 0;
    position: absolute;
    z-index: 5;
    background-color: #fff;
    border-radius: 0;
    display: block;
    right: 0;
    left: -30px;
    padding: 8px 0;
    top: 100%;
    visibility: hidden;
    transform: scaleY(0);
    opacity: 0;
    min-width: 220px;
    box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.05);
    margin: 0;
    transform-origin: 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.navbar .dropdown-menu .dropdown-item {
    color: #505050;
    font-size: 14px;
    font-weight: 600;
    padding: 9px 25px;
    display: block;
    transition: all 0.3s ease-in-out;
}

.navbar .dropdown-menu .dropdown-item:hover {
    transform: translatex(5px);
    background-color: transparent;
    -webkit-transform: translatex(5px);
    -moz-transform: translatex(5px);
    -ms-transform: translatex(5px);
    -o-transform: translatex(5px);
}

.navbar .dropdown:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.navbar .navbar-nav .nav-item.active > .nav-link {
    position: relative;
}

/*.sect-title {*/
/*    font-size: 45px;*/
/*    line-height: 1.17;*/
/*}*/


.d-xxl-inline-block {
    display: inline-block !important;

}

.img-fluid {
    max-width: 100%;
    height: auto;
}


.about-img-wrapper {
    position: relative;
    z-index: 1;
}

.about-img-wrapper .img-two {
    margin-top: 110px;
}

.mrn-300 {

    margin-right: -300px;

}


.experience-box2 {
    border-radius: 6px;
    display: inline-block;
    text-align: center;
    padding: 40px 34px;
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 180px;
    bottom: 30px;
}


.heading {
    max-width: 610px;
    line-height: 1.4;
    /*font-weight: 700;*/
    font-size: 2.3rem;
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;
}

mark {
    color: #fc5a13;
    background-color: transparent;
}

.content-wrap {
    box-shadow: 0 18px 40px rgb(51 51 51 / 10%);
    background: #fff;
    border-radius: 5px;
    padding: 50px 30px 63px;
    text-align: center;
    align-items: center;
    position: relative;
}

.content-wrap .text {
    font-size: 15px;
}


.tm-box-image.style-01 {
    height: 100%;
    padding-bottom: 58px;
}

.tm-box-image.style-01 .heading {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.25;
    margin-bottom: 9px;
}

.tm-box-image.style-01 .images {
    margin-bottom: 42px;
}

.tm-box-image.style-01 .circle-arrow {
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translate(-50%, 50%);
    -ms-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
    height: 56px;
    width: 56px;
}



.bg-red {
    background: linear-gradient(160deg, #132e54 0%, #1f4780 100%);
}

.text-57004-red {
    color: #cb213a !important;
}



.nav-item:focus > a, .nav-item:hover > a {
    color: #cb213a !important;
    transition: all 0.4s ease;
}



.item1 {
    background-repeat: no-repeat;
    background-attachment: inherit;
    background-position: center top;
    background-image: url('/public/assets/img/page-header.jpg');
    background-size: cover;
}

.header_57004 {
    background-repeat: no-repeat;
    background-attachment: inherit;
    background-position: center top;
    background-image: url('/public/assets/img/57004/header.jpg');
    background-size: cover;
}


#right-main-slider {
    background-repeat: no-repeat;
    background-attachment: inherit;
    background-position: center top;
    background-image: url(/public/assets/img/travail_equipe.jpg);
    background-size: cover;
    padding-top: 800px;
}

#right-prsc-slider {
    background-repeat: no-repeat;
    background-attachment: inherit;
    background-position: center top;
    background-image: url(/public/assets/img/bureau-prescripteur.jpg);
    background-size: cover;
    padding-top: 800px;
}

#right-acq-slider {
    background-repeat: no-repeat;
    background-attachment: inherit;
    background-position: center top;
    background-image: url(/public/assets/img/maison-acquereurs.jpg);
    background-size: cover;
    padding-top: 800px;
}

#right-frn-slider {
    background-repeat: no-repeat;
    background-attachment: inherit;
    background-position: center top;
    background-image: url(/public/assets/img/fournisseur-chantier.jpg);
    background-size: cover;
    padding-top: 800px;
}


.svg-effect svg {
    width: 100%;
    height: 100%;
}

#slider {
    position: relative;
}

#slider a {
    color: black;
}

#slider .svg-effect {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 160px;
}

#slider .btn-outline-black:hover, #slider .btn-outline-black:hover a {
    color: #fff !important;
    /*background-color: #343a40;*/
    border-color: white;
}

#slider .btn-black:hover, #slider .btn-black:hover a {
    background: white;
    color: #333333 !important;
    border: none;
}


#slider-range {
    margin: 10px 7px;

}

.tm-grid.move-up .animate {
    -webkit-transition: 0.4s all, 2s 1s left cubic-bezier(0, 1, 1, 1);
    -moz-transition: 0.4s all, 2s 1s left cubic-bezier(0, 1, 1, 1);
    -o-transition: 0.4s all, 2s 1s left cubic-bezier(0, 1, 1, 1);
    transition: 0.4s all, 2s 1s left cubic-bezier(0, 1, 1, 1);
}

.tm-grid.move-up .animate:hover {
    -webkit-transform: translateY(-1em);
    -moz-transform: translateY(-1em);
    -o-transform: translateY(-1em);
    -ms-transform: translateY(-1em);
    transform: translateY(-1em);
    /*transition: all 2s;*/
}

.tm-grid.move-up .animate:hover .middle-arrow {
    background: #3bb672 !important;
}

.content-wrap {
    transition: all 0.4s;
    /*color: white;*/
}

.content-wrap:hover {
    background: #5E95FE !important
    /*color: white;*/
}

.tm-grid.move-up .animate:hover svg g {
    fill: white !important;
    transition: all 0.4s;
}

.tm-grid.move-up .animate:hover .text {
    color: white !important;
    transition: all 0.4s;
}

.tm-grid.move-up .animate:hover h4, .tm-grid.move-up .animate:hover .text {

    color: #333;
}


h2.divider-title {
    font-size: 3rem !important;
}

.acquereur-text-wrapper p {
    font-size: 1.1rem !important;
}


.img-margin-left {
    margin-left: -150px;
}

.mln-200 {
    margin-left: -200px;
}


.custom-shape-divider-bottom-1653473552 {

    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1653473552 svg {
    position: relative;
    display: block;
    width: calc(213% + 1.3px);
    height: 112px;
}

.custom-shape-divider-bottom-1653473552 .shape-fill {
    fill: #FFFFFF;
}

.custom-shape-divider-top-1653473207 {

    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1653473207 svg {
    position: relative;
    display: block;
    width: calc(267% + 1.3px);
    height: 96px;
}

.custom-shape-divider-top-1653473207 .shape-fill {
    fill: #FFFFFF;
}

.custom-shape-divider-top-1653467468 {

    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1653467468 svg {
    position: relative;
    display: block;
    width: calc(172% + 1.3px);
    height: 130px;
}

.custom-shape-divider-top-1653467468 .shape-fill {
    fill: #FFFFFF;
}

.acquereur-text-wrapper ul li {
    line-height: 2.5;
}

.bloc-h2 {
    font-size: 45px;
}

.bloc-btn {
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.bloc-p {
    font-size: 20px;
}

.wrapper-bloc {
    max-width: 1000px;
    margin: auto;
    position: relative;
}

.divider {
    position: relative;
}

.divider li {
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 10px;
    position: relative;
    text-align: left;
    line-height: 27px;

}

.sub-title {
    color: #fc5a13;
    font-weight: 600;
    text-transform: uppercase;
}

.row.mb-5.wrapper-txt-content {
    background: white;
    padding: 30px 20px;
    color: black;
    border-radius: 8px;
}

.list-icon li {
    padding-left: 0px !important;

}

.icon-list-items li {
    line-height: 37px !important;
}

.list-icon svg {
    width: 20px;
    height: auto;
    fill: #0092E4;
}


.separator-blue {

    margin-bottom: 50px;
    position: relative;
    /*background: linear-gradient(160deg, #fc5a13 0%, #FE506A 100%);*/
    /*background: linear-gradient(160deg, #FFDCE7 0%, #7AA3FE 100%);*/
    /*background: linear-gradient(160deg, #08c796 0%, #28a89c 100%);*/
    /*background: linear-gradient(160deg, #08c796 0%, #3aa9db 100%);*/
}

/* .elementor-background-overlay {*/
/*    background-image: url('/assets/img/bg.png');*/
/*    opacity: 1;*/
/*     height: 100%;*/
/*     width: 100%;*/
/*     top: 0;*/
/*     left: 0;*/
/*     position: absolute;*/
/*}*/


#coucou {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    position: absolute;
}

#demo-form {
    background: #1e272c;
    padding: 80px 0;
}

#demo-form input, #demo-form select {
    height: 45px;
    background: #374044;
    border: 1px solid #242222;
    color: grey !important;
}

#demo-form textarea {

    background: #374044;
    border: 1px solid #242222;
    color: grey !important;
}

#demo-form input::placeholder, #demo-form select::placeholder, #demo-form textarea::placeholder {

    color: grey !important;
}


#demo-form .btn-primary {
    width: 100%;
}

#multi-section {
    padding: 80px 0;
}

.list_item li {
    padding: 20px 0;
    font-size: 19px;
    font-weight: 600;
    color: #878484;
}

.single__box {
    font-weight: 500;
    /*background-color: #FFFFFF;*/
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 5px 20px 0px rgb(0 69 255 / 12%);
}

.box__icon {
    border-radius: 5px;
    width: 65px;
    height: 65px;
    text-align: center;
    position: absolute;
    left: 35px;
    top: 35px;
    font-size: 32px;
    color: #ffffff;
    background: #4a6e95;
    padding-top: 7px;
}

.single__box__layout__1 {
    transition: 0.5s;
    padding: 35px;
    padding-left: 120px;
    position: relative;
    box-shadow: 0 15px 40px rgb(0 0 0 / 8%);
}


input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

.auth-form strong {
    color: #7e7e7e;
}

.wrapper-login .form-check-label, .auth-form a, .registration-form a {
    font-family: 'Lato', sans-serif;
    font-weight: 300 !important;
    margin-bottom: 20px;
    color: #7e7e7e;
    font-size: 1.063rem;
}

select.form-control {
    -webkit-appearance: menulist;
}

.bg-warning ul {
    margin-bottom: 0px !important;
}

/*.crop {*/
/*    height: 240px;*/
/*    width: 415px;*/
/*    overflow: hidden;*/
/*}*/

.crop {
    width: 415px; /* Largeur fixe du conteneur d'image */
    height: 240px; /* Hauteur fixe du conteneur d'image */
    overflow: hidden; /* Masquer tout dépassement d'image */
    width: 100%;
}


/*Media Queries*/
@media (min-width: 1200px) {


}


.crop img {
    /*height: 240px;*/
    /*width: auto;*/
    /*min-width: 415px;*/

    width: 100%; /* Largeur minimale de l'image */
    height: 100%; /* Hauteur minimale de l'image */
    width: auto; /* Laisser l'image s'adapter à la largeur minimale */
    height: auto; /* Laisser l'image s'adapter à la hauteur minimale */
    object-fit: cover; /* Remplir le conteneur sans déformer l'image */
}

.badge-delivered {
    position: absolute;
    top: 5px;
    left: 5px;
    background: #1A2E50;
    color: white;
    font-size: 12px;
    padding: 5px;

}

.badge-in-progress {
    position: absolute;
    top: 5px;
    left: 5px;
    background: #cb213a;

    color: white;
    font-size: 12px;
    padding: 5px;

}

.section-products {
    padding: 80px 0 54px;
}

.section-products .header {
    margin-bottom: 50px;
}

.section-products .header h3 {
    font-size: 1rem;
    color: #fe302f;
    font-weight: 500;
}

.section-products .header h2 {
    font-size: 2.2rem;
    font-weight: 400;
    color: #444444;
}

.section-products .single-product {
    margin-bottom: 26px;
}

.section-products .single-product .part-1 {
    position: relative;
    height: 290px;
    max-height: 290px;
    margin-bottom: 20px;
    overflow: hidden;
}

.section-products .single-product .part-1::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*z-index: -1;*/
    transition: all 0.3s;
}

.section-products .single-product:hover .part-1::before {
    transform: scale(1.2, 1.2) rotate(5deg);
}

.section-products #product-1 .part-1::before {
    background: url("/public/assets/img/57004/hayange-le-marechal.jpg") no-repeat center;
    background-size: cover;
    transition: all 0.3s;
}

.section-products #product-2 .part-1::before {
    background: url("https://www.t1.viteimmo.com/assets/img/57004/hayange-le-marechal.jpg") no-repeat center;
    background-size: cover;
}

.section-products #product-3 .part-1::before {
    background: url("https://i.ibb.co/L8Nrb7p/1.jpg") no-repeat center;
    background-size: cover;
}

.section-products #product-4 .part-1::before {
    background: url("https://i.ibb.co/cLnZjnS/2.jpg") no-repeat center;
    background-size: cover;
}

.section-products .single-product .part-1 .discount,
.section-products .single-product .part-1 .new {
    position: absolute;
    top: 15px;
    left: 20px;
    color: #ffffff;
    background-color: #fe302f;
    padding: 2px 8px;
    text-transform: uppercase;
    font-size: 0.85rem;
}

.section-products .single-product .part-1 .new {
    left: 0;
    background-color: #444444;
}

.section-products .single-product .part-1 ul {
    position: absolute;
    bottom: -41px;
    left: 20px;
    margin: 0;
    padding: 0;
    list-style: none;
    opacity: 0;
    transition: bottom 0.5s, opacity 0.5s;
}

.section-products .single-product:hover .part-1 ul {
    bottom: 30px;
    opacity: 1;
}

.section-products .single-product .part-1 ul li {
    display: inline-block;
    margin-right: 4px;
}

.section-products .single-product .part-1 ul li a {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: #ffffff;
    color: #444444;
    text-align: center;
    box-shadow: 0 2px 20px rgb(50 50 50 / 10%);
    transition: color 0.2s;
}

.section-products .single-product .part-1 ul li a:hover {
    color: #fe302f;
}

.section-products .single-product .part-2 .product-title {
    font-size: 1rem;
}

.section-products .single-product .part-2 h4 {
    display: inline-block;
    font-size: 1rem;
}

.section-products .single-product .part-2 .product-old-price {
    position: relative;
    padding: 0 7px;
    margin-right: 2px;
    opacity: 0.6;
}

.section-products .single-product .part-2 .product-old-price::after {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #444444;
    transform: translateY(-50%);
}


#arrow {
    display: block;
    width: 60px;
    height: auto;
    animation: bounce 2s infinite;
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(0);
    padding-bottom: 10%;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

.wrap-icon {
    border: 1px solid #d9d4d4;
    margin: 30px;
    padding: 30px;
    border-radius: 30px;
}


#a-propos-text img {
    float: left;
    margin: 0 20px 20px 0;
}



.table-responsive .scroll-top {
    overflow-x: auto;
    position: relative;
}

.table-responsive .scroll-top::before {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background: transparent;
    position: sticky;
    top: 0;
    width: 50px;
    height: 50px;
}

/* Styles du bouton burger */
.menu-toggle {
    position: fixed;
    background: white;
    border: none;
    font-size: 24px;
    cursor: pointer;
    z-index: 1001;
    transform: translateY(-50%);
    font-size: 24px;
}

/* Menu latéral caché par défaut */
.sidebar-menu {
    position: fixed;
    top: 0;
    left: -250px; /* Caché en dehors de l'écran */
    width: 250px;
    height: 100%;
    background: white;
    color: white;
    transition: left 0.3s ease-in-out;
    padding: 20px;
    z-index: 1000;
    overflow-y: auto;
}

/* Afficher le menu lorsqu'il est actif */
.sidebar-menu.active {
    left: 0;
}
#wrap-menu-toggle {
    margin-right: 80px !important;

}



@media (min-width: 1200px) {
    .w-xl-75 {
     width: 71% !important;
    }
    .position-xl-absolute {
        position: absolute !important;
    }

    .w-xl-50{
        width: 50% !important;
    }
}



@media (min-width: 992px) {
    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%;
    }
}

@media (min-width: 768px) {
    .bottom-md-0 {
        bottom: 0 !important;
    }
}



